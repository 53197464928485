<template>
  <div class="a15-index">
    <div class="animated fadeIn slow title">
      {{ title }}
    </div>
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSave">保存</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="checkAccountList">台账</el-button>
        </el-form-item>
        <el-form-item v-if="errors.length">
          <el-button type="primary" @click="handleLocateError">下一个错误</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <div ref="accountListA15" class="account-list-a15"></div>

    <el-drawer
      title="计量台账"
      :visible.sync="showAccountList"
      :with-header="false"
      size="1030"
    >
      <div v-if="tableHeight" class="account-list" :style="accountListStyle">
        <div ref="accountList" style=""></div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import auth from '@/common/auth'
import utility from '@/common/utility'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import moment from 'moment'
import a15Service from '@/services/a15Service'
// import config from '@/config'

export default {
  name: 'A15AccountListDetail',
  components: {
    MySearchBar
  },
  data () {
    return {
      accountList: [],
      tableHeight: 0,
      showAccountList: false,
      errors: [],
      errorStyle: new GC.Spread.Sheets.Style(),
      normalStyle: new GC.Spread.Sheets.Style(),
      currentErrorIndex: 0,
      columns: [
        'hidden1',
        'hidden2',
        'accountListId',
        'meterageDisplayCode',
        'meterageName',
        'startMileage',
        'endMileage',
        'part',
        'pictureNo',
        'formula',
        'num',
        'certNo',
        'manualSerialNo',
        'a15Date'
      ]
    }
  },
  computed: {
    title () {
      return `${this.tenderName}标段 第${this.periodName}期 编号：${this.examinationCode}`
    },
    accountListStyle () {
      return {
        height: this.tableHeight + 'px'
      }
    },
    ...mapGetters('examination', {
      examinationGuid: 'getExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      periodName: 'getPeriodName',
      examinationCode: 'getExaminationCode'
    })
  },
  watch: {
    errors (newVal, oldVal) {
      oldVal.forEach(errorRowIndex => {
        this.accountListA15Workbook.getActiveSheet().setStyle(parseInt(errorRowIndex), -1, this.normalStyle, GC.Spread.Sheets.SheetArea.viewport)
      })
      newVal.forEach(errorRowIndex => {
        this.accountListA15Workbook.getActiveSheet().setStyle(parseInt(errorRowIndex), -1, this.errorStyle, GC.Spread.Sheets.SheetArea.viewport)
      })
      if (newVal && newVal.length) {
        this.currentErrorIndex = 0
        this.accountListA15Workbook.getActiveSheet().showRow(this.errors[this.currentErrorIndex], GC.Spread.Sheets.VerticalPosition.top)
      }
    }
  },
  methods: {
    handleSave () {
      const json = this.accountListA15Workbook.getActiveSheet().toJSON().data.dataTable
      const a15List = this.jsonArray2A15List(json)
      if (!a15List.length) {
        this.$message.warning('未提交任何数据')
        return
      }

      if (a15List.some(item => !item.accountListId)) {
        this.errors = a15List.filter(item => !item.accountListId).map(item => item.rowNum)
        this.$message.error('必须填写台账编号')
        return
      }

      this.errors = []

      a15Service.batchAdd(this.periodName, a15List).then(res => {
        if (res.data.code === 1) {
          this.$message.success('操作成功')
          this.$router.go(-1)
        } else {
          this.$message.error('操作失败')
          this.parseErrors(res.data.data)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    parseErrors (data) {
      this.errors = data
        // .filter(error => error.cause === config.meterageListOverflowCode)
        .map(error => error.rowNum)
        .sort()
    },
    checkAccountList () {
      if (this.accountList.length === 0) {
        this.$message.error('台账尚未加载成功，请稍后')
        return
      }
      this.showAccountList = true
      this.$nextTick(() => {
        this.initAccountList()
      })
    },
    handleLocateError () {
      this.currentErrorIndex = (++this.currentErrorIndex) % this.errors.length
      this.accountListA15Workbook.getActiveSheet().showRow(this.errors[this.currentErrorIndex], GC.Spread.Sheets.VerticalPosition.top)
    },
    computeHeight () {
      this.tableHeight = document.body.offsetHeight
    },
    async getAccountListAsync () {
      const result = await axios.get(utility.getFullUrl('meteragelist/GetAccountLists'), {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        },
        params: {
          tenderguid: this.tenderGuid
        }
      })

      this.accountList = result.data.data
    },
    initAccountListA15 () {
      axios.get('/excel/accountListA15.xlsx', {
        responseType: 'blob'
      }).then(res => {
        this.excelIo = new GC.Spread.Excel.IO()
        this.excelIo.open(res.data, json => {
          if (!this.accountListA15Workbook) {
            this.accountListA15Workbook = new GC.Spread.Sheets.Workbook(this.$refs.accountListA15)
          }
          this.accountListA15Workbook.fromJSON(json)
          this.accountListA15Workbook.getActiveSheet().setRowCount(5000)

          this.initAccountListSpreadFilters()
        })
      })
    },
    initExcelButtons () {
      // const sheet = this.accountListWorkbook.getActiveSheet()
      // const buttonCellType = new GC.Spread.Sheets.CellTypes.Button()
      // buttonCellType.buttonBackColor('#3d8ab6')
      // buttonCellType.text('选择')
      // sheet.getRange(1, 0, this.accountList.length, 1).cellType(buttonCellType)
      this.initExcelButtonsChunk()
      if (!this.buttonEventBinded) {
        this.buttonEventBinded = true
        this.accountListWorkbook.bind(GC.Spread.Sheets.Events.ButtonClicked, (e, args) => {
          const cellType = args.sheet.getCellType(args.row, args.col)
          if (cellType instanceof GC.Spread.Sheets.CellTypes.Button) {
            const a15Sheet = this.accountListA15Workbook.getActiveSheet()
            const rowIndex = parseInt(a15Sheet.getCell(0, 1).value())
            for (let j = 1; j < 9; ++j) {
              a15Sheet.setValue(rowIndex, j + 1, args.sheet.getCell(args.row, j).value())
            }
          }
        })
      }
    },
    initExcelButtonsChunk () {
      const sheet = this.accountListWorkbook.getActiveSheet()
      const buttonCellType = new GC.Spread.Sheets.CellTypes.Button()
      buttonCellType.buttonBackColor('#3d8ab6')
      buttonCellType.text('选择')
      let rowIndex = 0
      const _run = () => {
        if (rowIndex === this.accountList.length) {
          return
        }
        requestIdleCallback(idle => {
          while (idle.timeRemaining() > 0 && rowIndex < this.accountList.length) {
            sheet.getCell(rowIndex + 1, 0).cellType(buttonCellType)
            ++rowIndex
            _run()
          }
        })
      }
      _run()
    },
    initAccountListSpreadFilters () {
      const sheet = this.accountListA15Workbook.getSheet(0)
      sheet.suspendPaint()
      sheet.options.allowCellOverflow = true
      const filter = new GC.Spread.Sheets.Filter.HideRowFilter(new GC.Spread.Sheets.Range(1, 2, 5000, 11))
      sheet.rowFilter(filter)
      sheet.resumePaint()
      this.$nextTick(() => {
        filter.filterButtonVisible(1, true)
      })
    },
    initAccountList () {
      axios.get('/excel/accountList.xlsx', {
        responseType: 'blob'
      }).then(res => {
        this.excelIo = new GC.Spread.Excel.IO()
        this.excelIo.open(res.data, json => {
          if (!this.accountListWorkbook) {
            this.accountListWorkbook = new GC.Spread.Sheets.Workbook(this.$refs.accountList)
          }
          this.accountListWorkbook.fromJSON(json)
          this.accountListWorkbook.getActiveSheet().setRowCount(this.accountList.length + 1)
          this.accountListWorkbook.getActiveSheet().setArray(1, 1, this.accountList2Array(this.accountList))

          this.$nextTick(() => {
            this.initExcelButtons()
          })
        })
      })
    },
    accountList2Array (list) {
      return list.map(item => ([
        item.accountListId,
        item.meterageDisplayCode,
        item.meterageName,
        item.startMileage,
        item.endMileage,
        item.part,
        item.pictureNo,
        item.formula,
        item.remainNum,
        item.pictureNum,
        item.declareNum
      ]))
    },
    jsonArray2A15List (json) {
      let result = []

      for (let rowIndex of Object.keys(json)) {
        if (rowIndex === '0') {
          continue
        }
        if (parseInt(rowIndex) >= parseInt(this.accountListA15Workbook.getActiveSheet().getCell(0, 1).value())) {
          continue
        }
        const row = json[rowIndex]

        const a15Model = {
          rowNum: rowIndex,
          examinationGuid: this.examinationGuid,
          tenderGuid: this.tenderGuid
        }

        this.columns.forEach((key, index) => {
          a15Model[key] = row[`${index}`] ? (row[`${index}`].value ? row[`${index}`].value.toString() : '') : ''
        })

        if (a15Model.a15Date && a15Model.a15Date.indexOf('(') !== -1 && a15Model.a15Date.indexOf(')') !== -1) {
          let diffDays = a15Model.a15Date.substring(a15Model.a15Date.indexOf('(') + 1, a15Model.a15Date.indexOf(')'))
          let startDate = moment([1899, 11, 30]).add(parseInt(diffDays), 'days').format('YYYY-MM-DD')
          a15Model.a15Date = startDate
        }

        a15Model.num = isNaN(a15Model.num) ? 0 : Number(a15Model.num)
        a15Model.accountListId = `${a15Model.accountListId}`

        result.push(a15Model)
      }

      return result
    }
  },
  mounted () {
    this.computeHeight()
    this.getAccountListAsync()
    this.initAccountListA15()
    this.errorStyle.backColor = 'red'
    this.errorStyle.foreColor = 'white'
    this.normalStyle.backColor = 'white'
    this.normalStyle.foreColor = 'black'
    this.normalStyle.borderLeft = new GC.Spread.LineBorder('black', GC.Spread.LineStyle.hair)
    this.normalStyle.borderTop = new GC.Spread.LineBorder('black', GC.Spread.LineStyle.hair)
    this.normalStyle.borderRight = new GC.Spread.LineBorder('black', GC.Spread.LineStyle.hair)
    this.normalStyle.borderBottom = new GC.Spread.LineBorder('black', GC.Spread.LineStyle.hair)
  }
}
</script>

<style scoped lang="scss">
.a15-index {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 60px 1fr;
  & .account-list-a15 {
    height: 100%;
    width: 100%;
  }
}

.account-list {
  width: 1650px;
  height: 600px;
  & > div {
    width: 100%;
    height: 100%;
  }
}

.title {
  position: absolute;
  line-height: 40px;
  font-size: 14px;
  top: 65px;
  right: 165px;
  padding-right: 5px;
  color: red;
}
</style>
